import {
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNonEmptyString,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  IElement,
  IElementWithFileUri,
  IElementWithPixelSize,
  IElementWithUri,
  Img360LevelsOfDetail,
  ImgSheetLevelsOfDetail,
  ImgSheetLevelsOfDetailSource,
  PolygonPoint,
  PolygonPointState,
} from "../i-element";
import { IElementBase, IElementType } from "../i-element-base";
import {
  IBoundingBox,
  IGlobalPose,
  IGPS,
  ILabel,
  IPose,
  IQuat,
  IRefCoordSystemTransform,
  isLabelType,
  IVec3,
  LabelType,
} from "../properties";

/**
 * @returns True if the input object is a valid LabelType
 * @param labelType The object to validate
 */
function validateLabelType(labelType: unknown): labelType is LabelType {
  if (!labelType) {
    return false;
  }
  if (typeof labelType !== "string" || labelType.length === 0) {
    return false;
  }
  return isLabelType(labelType);
}

/**
 * Validate that an object is a valid ILabel
 *
 * @param obj to check
 * @returns true if it matches the ILabel interface
 */
export function validateLabel(obj: unknown): obj is ILabel {
  if (!validateNotNullishObject(obj, "ILabel")) {
    return false;
  }

  const label: Partial<ILabel> = obj;

  return (
    validateNonEmptyString(label, "id") &&
    validateNonEmptyString(label, "name") &&
    validateNonEmptyString(label, "createdAt") &&
    validateNonEmptyString(label, "createdBy") &&
    validateNonEmptyString(label, "resourceId") &&
    validateOfType(label, "labelType", validateLabelType, PropOptional) &&
    validatePrimitive(label, "lastModifiedInDb", "string", PropOptional)
  );
}

/**
 * Validate that an object is a valid IVec3
 *
 * @param obj to check
 * @returns true if it matches the IVec3 interface
 */
export function validateVec3(obj: unknown): obj is IVec3 {
  if (!validateNotNullishObject(obj, "IVec3")) {
    return false;
  }
  const vec3: Partial<IVec3> = obj;

  return (
    validatePrimitive(vec3, "x", "number") &&
    validatePrimitive(vec3, "y", "number") &&
    validatePrimitive(vec3, "z", "number")
  );
}

/**
 * Validate that an object is a valid PolygonPoint
 *
 * @param obj to check
 * @returns true if it matches the PolygonPoint interface
 */
export function validatePolygonPoint(obj: unknown): obj is PolygonPoint {
  if (!validateVec3(obj)) {
    return false;
  }
  const point: Partial<PolygonPoint> = obj;
  return (
    validatePrimitive(point, "state", "number", PropOptional) ||
    validateEnumValue(point.state, PolygonPointState)
  );
}

/**
 * Validate that an object is a valid IQuat
 *
 * @param obj to check
 * @returns true if it matches the IQuat interface
 */
export function validateQuat(obj: unknown): obj is IQuat {
  if (!validateNotNullishObject(obj, "IQuat")) {
    return false;
  }

  const quat: Partial<IQuat> = obj;

  return (
    validatePrimitive(quat, "x", "number") &&
    validatePrimitive(quat, "y", "number") &&
    validatePrimitive(quat, "z", "number") &&
    validatePrimitive(quat, "w", "number")
  );
}

/**
 * Validate that an object is a valid IGPS
 *
 * @param obj to check
 * @returns true if it matches the IGPS interface
 */
export function validateGps(obj: unknown): obj is IGPS {
  if (!validateNotNullishObject(obj, "IGPS")) {
    return false;
  }

  const gps: Partial<IGPS> = obj;

  return (
    validateNotNullishObject(gps.gpsPos, "gpsPos") &&
    validatePrimitive(gps.gpsPos, "type", "string") &&
    validateArrayOf({
      object: gps.gpsPos,
      prop: "coordinates",
      elementGuard: (x) => typeof x === "number",
      size: 2,
    }) &&
    validatePrimitive(gps, "altitude", "number", PropOptional) &&
    validatePrimitive(gps, "latLongAcc", "number") &&
    validatePrimitive(gps, "altiAcc", "number", PropOptional)
  );
}

/**
 * Validate that an object is a valid IRefCoordSystemTransform
 *
 * @param obj to check
 * @returns true if it matches the IRefCoordSystemTransform interface
 */
export function validateRefCoordSystemMatrix(
  obj: unknown,
): obj is IRefCoordSystemTransform {
  return (
    validateNotNullishObject<IRefCoordSystemTransform>(
      obj,
      "IRefCoordSystemMatrix",
    ) &&
    validateOfType(obj, "scale", validateVec3, PropOptional) &&
    validateOfType(obj, "rot", validateQuat, PropOptional) &&
    validateOfType(obj, "pos", validateVec3, PropOptional) &&
    validatePrimitive(obj, "preScaleZ", "number", PropOptional)
  );
}

/**
 * Validate that an object is a valid IPose
 *
 * @param obj to check
 * @returns true if it matches the IPose interface
 */
export function validatePose(obj: unknown): obj is IPose {
  if (!validateNotNullishObject(obj, "IPose")) {
    return false;
  }

  const pose: Partial<IPose> = obj;

  return (
    validateOfType(pose, "pos", validateVec3, PropOptional) &&
    validateOfType(pose, "scale", validateVec3, PropOptional) &&
    validatePrimitive(pose, "isWorldRot", "boolean") &&
    validateOfType(pose, "rot", validateQuat, PropOptional) &&
    validateOfType(pose, "gps", validateGps, PropOptional)
  );
}

/**
 * Validate that an object is a valid IGlobalPose
 *
 * @param obj to check
 * @returns true if it matches the IGlobalPose interface
 */
export function validateGlobalPose(obj: unknown): obj is IPose {
  if (!validateNotNullishObject(obj, "IGlobalPose")) {
    return false;
  }

  const pose: Partial<IGlobalPose> = obj;

  return (
    validateOfType(pose, "pos", validateVec3) &&
    validateOfType(pose, "scale", validateVec3) &&
    validateOfType(pose, "rot", validateQuat)
  );
}

/**
 * Validate that an object is a valid IBoundingBox
 *
 * @param obj to check
 * @returns true if it matches the IBoundingBox interface
 */
export function validateBoundingBox(obj: unknown): obj is IPose {
  if (!validateNotNullishObject(obj, "IBoundingBox")) {
    return false;
  }

  const boundingBox: Partial<IBoundingBox> = obj;

  return (
    validateOfType(boundingBox, "min", validateVec3) &&
    validateOfType(boundingBox, "max", validateVec3) &&
    !!boundingBox.min &&
    !!boundingBox.max &&
    boundingBox.min.x <= boundingBox.max.x &&
    boundingBox.min.y <= boundingBox.max.y &&
    boundingBox.min.z <= boundingBox.max.z
  );
}

/**
 * Validate that an object is a valid LevelsOfDetailSource
 *
 * @param obj to check
 * @returns true if it matches the LevelsOfDetailsSource interface
 */
export function validateImgSheetLevelsOfDetailSource(
  obj: unknown,
): obj is ImgSheetLevelsOfDetailSource {
  if (!validateNotNullishObject(obj, "LevelsOfDetailSource")) {
    return false;
  }

  const source: Partial<ImgSheetLevelsOfDetailSource> = obj;
  return (
    validatePrimitive(source, "x", "number") &&
    validatePrimitive(source, "y", "number") &&
    validatePrimitive(source, "source", "string")
  );
}

/**
 * Validate that an object is a valid ImgSheetLevelsOfDetail
 *
 * @param obj to check
 * @returns true if it matches the ImgSheetLevelsOfDetail interface
 */
export function validateImgSheetLevelsOfDetail(
  obj: unknown,
): obj is ImgSheetLevelsOfDetail {
  if (!validateNotNullishObject(obj, "ImgSheetLevelsOfDetail")) {
    return false;
  }

  const lod: Partial<ImgSheetLevelsOfDetail> = obj;
  return (
    validatePrimitive(lod, "level", "number") &&
    validatePrimitive(lod, "dimX", "number") &&
    validatePrimitive(lod, "dimY", "number") &&
    validateArrayOf({
      object: lod,
      prop: "sources",
      elementGuard: validateImgSheetLevelsOfDetailSource,
    })
  );
}

/**
 * Validate that an object is a valid Img360LevelsOfDetail
 *
 * @param obj to check
 * @returns true if it matches the Img360LevelsOfDetails interface
 */
export function validateImg360LevelsOfDetail(
  obj: unknown,
): obj is Img360LevelsOfDetail {
  if (!validateNotNullishObject(obj, "Img360LevelsOfDetails")) {
    return false;
  }

  const lod: Partial<Img360LevelsOfDetail> = obj;
  return (
    validatePrimitive(lod, "level", "number") &&
    validatePrimitive(lod, "dimX", "number") &&
    validatePrimitive(lod, "dimY", "number") &&
    validateArrayOf({
      object: lod,
      prop: "sources",
      elementGuard: (el) => typeof el === "string",
      size: (lod.dimX ?? 0) * (lod.dimY ?? 0),
    }) &&
    validateArrayOf({
      object: lod,
      prop: "signedSources",
      elementGuard: (el) => typeof el === "string",
      size: (lod.dimX ?? 0) * (lod.dimY ?? 0),
      optionality: PropOptional,
    }) &&
    validateNonEmptyString(lod, "signedSourcesExpiresOn", PropOptional)
  );
}

/**
 * @returns true if the object is a valid IElementWithUri
 * @param iElement to test
 */
export function validateIElementWithUri(
  iElement: unknown,
): iElement is IElementWithUri {
  if (!validateIElementBase(iElement)) return false;
  const toCheck: Partial<IElementWithUri> = iElement;

  return (
    validateNonEmptyString(toCheck, "uri") &&
    validatePrimitive(toCheck, "signedUri", "string", PropOptional) &&
    validatePrimitive(toCheck, "signedUriExpiresOn", "string", PropOptional)
  );
}

/**
 * @returns true if the object is a valid IElementWithFileUri
 * @param iElement to test
 */
export function validateIElementWithFileUri(
  iElement: unknown,
): iElement is IElementWithFileUri {
  if (!validateIElementBase(iElement)) return false;

  const toCheck: Partial<IElementWithFileUri> = iElement;

  return (
    validateIElementWithUri(iElement) &&
    validateNonEmptyString(toCheck, "md5Hash", PropOptional) &&
    validatePrimitive(toCheck, "fileSize", "number", PropOptional) &&
    validateNonEmptyString(toCheck, "fileName", PropOptional)
  );
}

/**
 * @returns true if the object is a valid IElementWithPixelSize
 * @param iElement to test
 */
export function validateIElementWithPixelSize(
  iElement: unknown,
): iElement is IElementWithPixelSize {
  if (!validateIElementBase(iElement)) return false;

  const toCheck: Partial<IElementWithPixelSize> = iElement;

  return (
    validateIElementWithFileUri(iElement) &&
    validatePrimitive(toCheck, "pixelWidth", "number") &&
    validatePrimitive(toCheck, "pixelHeight", "number")
  );
}

/**
 * @returns true if a string is one of the known IElementType values
 * @param type the string to check
 */
export function validateKnownIElementTypes(type: string): type is IElementType {
  return Object.values<string>(IElementType).includes(type);
}

/**
 * Check if the provided object has all the mandatory attributes of the IElementBase type
 *
 * An IElementBase does not guarantee that all the props of a specific IElement are present
 *
 * @see validateIElement for a more in-depth validation
 * @param obj Object to check
 * @returns whether or not the object is a valid IElementBase
 */
export function validateIElementBase(obj: unknown): obj is IElementBase {
  if (!validateNotNullishObject(obj, "IElement")) {
    return false;
  }

  const element: Partial<IElement> = obj;

  return (
    validateNonEmptyString(element, "id") &&
    validateNonEmptyString(element, "rootId") &&
    validateArrayOf({
      object: element,
      prop: "childrenIds",
      elementGuard: (x) => typeof x === "string",
      optionality: PropOptional,
    }) &&
    validatePrimitive(element, "type", "string") &&
    validatePrimitive(element, "typeHint", "string", PropOptional) &&
    validatePrimitive(element, "name", "string") &&
    validatePrimitive(element, "descr", "string", PropOptional) &&
    validateNonEmptyString(element, "parentId", PropOptional) &&
    validatePrimitive(element, "createdBy", "string") &&
    validateNonEmptyString(element, "createdAt") &&
    validatePrimitive(element, "modifiedBy", "string") &&
    validateNonEmptyString(element, "modifiedAt") &&
    validatePrimitive(element, "thumbnailUri", "string", PropOptional) &&
    validateOfType(element, "pose", validatePose, PropOptional) &&
    validateOfType(element, "globalPose", validateGlobalPose, PropOptional) &&
    validateOfType(element, "boundingBox", validateBoundingBox, PropOptional) &&
    validatePrimitive(element, "metaDataMap", "object", PropOptional) &&
    validateNonEmptyString(element, "lastModifiedInDb", PropOptional) &&
    validateArrayOf({
      object: element,
      prop: "labels",
      elementGuard: validateLabel,
      optionality: PropOptional,
    }) &&
    validateOfType(
      element,
      "refCoordSystemMatrix",
      validateRefCoordSystemMatrix,
      PropOptional,
    )
  );
}
