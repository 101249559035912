import { GUID, ISOTimeString, UserId } from "./common";

/**
 * The known types used to classify the labels in a project.
 * Update this list if new types will be added to the project API.
 * See https://faro01.atlassian.net/wiki/spaces/SMETA/pages/4538597409/Overview+on+Labels+in+the+Project+API#System-Labels
 */
export enum LabelType {
  SpotWalkMarker = "SpotWalk Marker",
  LocationMarker = "Location Marker",
  Uncategorized = "Uncategorized",
  Navisworks = "navisworks",
  Revit = "revit",
  ContinuousCapture = "continuous-capture",
  Videomode = "videomode",
  Category = "category",
  PrivacyBlurring = "privacy-blurring",
  SitescapeScan = "sitescape-scan",
  MergedPointCloud = "merged-point-cloud",
  ObjectDetection = "object-detection",
  Initializing = "initializing",
}

/**
 * @returns True if the input string is a known tag type
 * @param type The type to check
 */
export function isLabelType(type: string): type is LabelType {
  return Object.values<string>(LabelType).includes(type);
}

/** A user-created label. */
export interface ILabel {
  /** The global unique identifier of the label. */
  id: GUID;

  /** The name of the label. */
  name: string;

  /** The date time when the label was created. */
  createdAt: ISOTimeString;

  /** The ID of the user who created the label. */
  createdBy: UserId;

  /**
   * The scope the Label can be used in (core backend ID, Project/Folder/Company).
   *
   * This is usually the ID of the project where the label is used.
   */
  resourceId: string;

  /** The type of the label. */
  labelType?: LabelType | null;

  /**
   * The UTC time when the label was last synced/changed to the DB.
   * If null then the label was never stored to the DB yet.
   */
  lastModifiedInDb: ISOTimeString | null;
}
